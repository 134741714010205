import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoModule } from 'ngx-socket-io';
import {
  GoogleTranslationService,
  GoogleObj,
} from './services/google-translation/google-translation.service';
import { MessagingService } from './services/messaging/messaging-service';
import { WebsocketService } from './services/websocket/websocket.service';
import { CallService } from './services/call/call.service';
// import { io } from "socket.io-client";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Hi2u';
  data = {
    title: '<h1>hello world</h1>',
    description: '<p>demo world</p>',
    detail: '<strong>demo hello.....</strong>',
  };

  message: any;
  iosAudioregistration = 0;
  constructor(
    public translate: TranslateService,
    private google: GoogleTranslationService,
    private messagingService: MessagingService,
    private __websocket: WebsocketService,
    private __caller: CallService
  ) {
    // let src = 'assets/ringing/swinging-617.ogg'
    // let audio = new Audio(src)
    // audio.load();
    // //////console.log("audio section------------");
    // audio.play()
    // setTimeout(()=>{
    //   audio.pause()
    // },8000)
    // __websocket.setupSocket()
    // const socket = io("http://localhost:3020/");
    // translate.addLangs(['EN','FR']);
    // translate.setDefaultLang('EN')
    // translate.use('EN')
  }

  ngOnInit() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessaging();
    this.message = this.messagingService.currentMessage;

    const googleObj: GoogleObj = {
      // q: this.data.title,
      q: [this.data.title, this.data.description, this.data.detail],
      target: 'es',
    };
    this.google.translate(googleObj).subscribe((res: any) => {
      this.data = {
        title: res.data.translations[0].translatedText,
        description: res.data.translations[1].translatedText,
        detail: res.data.translations[2].translatedText,
      };
      // //////console.log("translated -----------------",this.data);
    });
    if (localStorage.getItem('portal_login_token')) {
      this.__websocket.setupSocket();
      this.__caller.attachEvents();
    }
    // check user agent to check ios

    this.__caller.audio1 = document.getElementById(
      'ringtone'
    ) as HTMLAudioElement;

    // document.body.addEventListener('canplaythrough', () => {
    //   if (!this.iosAudioregistration) return;
    //   this.__caller.audio1.play();
    //   // setTimeout(()=>{

    //   // })
    //   this.__caller.audio1.play().catch((e) => {
    //     window.addEventListener('click', () => {
    //       this.__caller.audio1.play();
    //     });
    //   });
    // });
    // document.body.addEventListener('touchstart', () => {
    //   this.iosAudioregistration += 1;
    //   if (this.iosAudioregistration != 2) return;

    //   this.__caller.audio1.play();
    //   document.title = 'audio';
    //   //console.log('volume', this.__caller.audio1.volume);
    //   setTimeout(() => {
    //     //console.log('audio ringtone', this.__caller.audio1.volume);
    //     this.__caller.audio1.pause();
    //     this.__caller.audio1.volume = 1;
    //     document.title = 'audio paused';
    //     //console.log('audio ringtone volume', this.__caller.audio1.volume);
    //   }, 1000);
    // });
  }
}
