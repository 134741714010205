import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject<any>(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private toastr: ToastrService
  ) {
    //     this.angularFireMessaging.requestPermission
    //   .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
    //   .subscribe((token) => {
    //     //console.log(token);
    //   });
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        //console.log(token)
        localStorage.setItem('device_token', token);
      },
      (err) => {
        //console.log("Unable to get permission to notify..",err)
      }
    );
  }
  receiveMessaging() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        //console.log("New messages received..",payload)
        let messageString =
          payload.notification.title + ' : ' + payload.notification.body;
        this.toastr.success(messageString);
        // this.currentMessage.next(payload);
      },
      (err) => {
        //console.log("error ",err)
      }
    );
  }
}
