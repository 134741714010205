import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  apiUrl: string;
  local_data: any;
  local_token: any;

  constructor(private __http: HttpClient) {
    this.apiUrl = environment.api_url;
    this.local_data = JSON.parse(localStorage.getItem('portal_login_data'));
    this.local_token = localStorage.getItem('portal_login_token');
  }

  list(user_id,upcoming='true',page='0',limit='10',is_blocked='',search_key='',lang_id=''): Observable<any> {
    return this.__http.get(`${this.apiUrl}/schedule`,{
      headers:{Authorization:this.local_token},
      params:{
        page,
        is_blocked,
        search_key,
        user_id,
        limit,
        upcoming
      }
    });
  }

  details(id:any): Observable<any> {
    return this.__http.get(`${this.apiUrl}/schedule/${id}`,{
      headers:{Authorization:this.local_token}
    });
  }
  edit(id:any,data:any): Observable<any> {
    return this.__http.put(`${this.apiUrl}/schedule/${id}`,data,{
      headers:{Authorization:this.local_token}
    });
  }
  createSchedule(data:any): Observable<any> {
    return this.__http.post(`${this.apiUrl}/schedule`,data,{
      headers:{Authorization:this.local_token}
    });
  }



  priceCalculation(data:any): Observable<any> {
    return this.__http.post(`${this.apiUrl}/schedule/calculate-price`,data,{
      headers:{Authorization:this.local_token}
    });
  }



  rejectSchedule(data:any): Observable<any> {
    return this.__http.post(`${this.apiUrl}/schedule/reject`,data,{
      headers:{Authorization:this.local_token}
    });
  }
  deleteSchedule(data:any): Observable<any> {
    return this.__http.delete(`${this.apiUrl}/schedule/${data}`,{
      headers:{Authorization:this.local_token}
    });
  }
  acceptSchedule(data:any): Observable<any> {
    return this.__http.post(`${this.apiUrl}/schedule/confirm`,data,{
      headers:{Authorization:this.local_token}
    });
  }
  reviseSchedule(data:any): Observable<any> {
    return this.__http.post(`${this.apiUrl}/schedule/confirm`,data,{
      headers:{Authorization:this.local_token}
    });
  }
  postpone(id:any,data:any): Observable<any> {
    return this.__http.put(`${this.apiUrl}/schedule/postPond/${id}`,data,{
      headers:{Authorization:this.local_token}
    });
  }

  refudRequest(data:any): Observable<any> {
    return this.__http.post(`${this.apiUrl}/schedule/refund-request/`,data,{
      headers:{Authorization:this.local_token}
    });
  }



}
