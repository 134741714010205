import { Injectable } from '@angular/core';
import Peer from 'peerjs';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { WebsocketService } from '../websocket/websocket.service';
import { UserService } from '../user/user.service';
import { ToastRef, ToastrService } from 'ngx-toastr';
import { CallerTosterComponent } from 'src/app/caller-toster/caller-toster.component';

@Injectable({
  providedIn: 'root',
})
export class CallService {
  private peer: Peer;
  private mediaCall: Peer.MediaConnection;
  private completeConnection;
  public elebelcallflag: boolean = false;
  public localStreamBs: BehaviorSubject<MediaStream> = new BehaviorSubject(
    null
  );
  public localStream$ = this.localStreamBs.asObservable();
  public remoteStreamBs: BehaviorSubject<MediaStream> = new BehaviorSubject(
    null
  );
  public remoteStream$ = this.remoteStreamBs.asObservable();
  public peerConnection: any;
  public isCallStartedBs = new BehaviorSubject<boolean>(false);
  public isCallStarted$ = this.isCallStartedBs.asObservable();
  public isUserJoinedBs = new BehaviorSubject<boolean>(false);
  public isUserJoined$ = this.isUserJoinedBs.asObservable();
  public onCallScreenBlur$ = new Subject<boolean>();
  public receiverDiv;
  public call_start_time;
  public call_end_time;
  public remote_user_id;
  public remoteCallFrom;
  private schedule_Data: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  getScheduleData = this.schedule_Data.asObservable();
  remoteUsers = {};
  subscribedStreams = [];
  userUid;
  isIncomingCall: boolean = false;
  userData: any;
  toastrRef: ToastRef<any>;
  callData: any;
  channalId: any;
  localTracks = {
    videoTrack: null,
    audioTrack: null,
  };
  videoCallInfo = {
    receiver_id: null,
    sender_id: null,
  };
  callingWindow: Window;
  calScheduledata: any;
  scheduled_id: any;
  public ringingFile = 'assets/ringing/swinging-617.mp3';
  public audio1 = null as HTMLAudioElement | null;
  public ringingAudio = new Audio(this.ringingFile);
  public callingEvent$ = new Subject<any>();

  constructor(
    private __websocket: WebsocketService,
    private socket: Socket,
    private __user: UserService,
    private toastr: ToastrService
  ) {}

  scheduleId(data: any) {
    this.schedule_Data.next(data);
  }

  async getRemotePlayerdata(userId) {
    return await new Promise((resolve, reject) => {
      this.__user.detailsUser(userId).subscribe(
        (response) => {
          if (response.data.profile_image != '') {
            response.data.profile_image =
              'https://hi2u.app:3020/' + response.data.profile_image;
          } else {
            response.data.profile_image = 'assets/imgs/profile-img.png';
          }
          resolve(response.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public attachEvents() {
    this.__websocket.socket.on('videocall', async (data) => {
      if (data.msg.schedule_id) {
        this.scheduled_id = data.msg.schedule_id;
      } else {
        this.scheduled_id = JSON.parse(localStorage.getItem('scheduleId'));
      }

      //console.log('service======================',this.scheduled_id)
      try {
        this.callData = data;
        switch (data.msg.type) {
          case 'request':
            //console.log(data, 'request', data);

            this.userData = await this.getRemotePlayerdata(data.msg.sender_id);
            //console.log(this.userData);
            this.ringingToneStart();
            const { toastRef } = this.toastr.show(
              'Calling',
              `${this.userData.first_name} ${this.userData.last_name}`,
              {
                timeOut: 500000, // To make it persistent until the user closes it
                progressBar: true, // Remove the progress bar
                closeButton: true, // Show a close button
                enableHtml: true, // Allow using HTML in the notification
                tapToDismiss: false, // Prevent automatic dismissal on click
                disableTimeOut: true,
                toastClass: 'custom-toster',
                toastComponent: CallerTosterComponent,
              }
            );
            this.toastrRef = toastRef;
            this.videoCallInfo.sender_id = data.msg.sender_id;
            this.videoCallInfo.receiver_id = data.msg.receiver_id;
            setTimeout(() => {
              let message = {
                sender_id: data.msg.receiver_id,
                receiver_id: data.msg.sender_id,
                type: 'not-answered',
                schedule_id: this.scheduled_id,
              };

              this.__websocket.socket.emit('videocall', message);
            }, 1000 * 120);
            break;

          case 'answer':
            this.setCallConnected();
            break;

          case 'not-answered':
            this.rejectCall();
            this.toastr.warning('User not available');
            break;

          case 'reject':
            this.rejectCall();
            break;

          case 'leave':
            this.rejectCall();
            this.setCallDisconnected();
            window.close();
            break;

          case 'connected':
            break;

          default:
            break;
        }
        this.callingEvent$.next(data);
      } catch (e) {
        console.log(e);
      }
    });

    this.__websocket.socket.on('error', (error) => {
      console.error('Socket.IO error:', error);
    });
  }

  public acceptedCall() {
    this.channalId = this.videoCallInfo.receiver_id;
    this.toastrRef.close();
    this.ringingToneEnd();
  }

  public setCallConnected() {
    this.isCallStartedBs.next(true);
    this.isIncomingCall = false;
  }

  public setCallDisconnected() {
    this.isCallStartedBs.next(false);
    this.isIncomingCall = false;
  }

  public rejectCall() {
    let message1 = {
      sender_id: this.videoCallInfo.receiver_id,
      receiver_id: this.videoCallInfo.sender_id,
      type: 'reject',
      schedule_id: this.scheduled_id,
    };
    this.__websocket.socket.emit('videocall', message1);

    this.callData = null;
    this.isIncomingCall = false;
    this.userData = null;
    this.toastrRef?.close();
    this.ringingToneEnd();
  }

  public rejectCallEv() {
    let message = {
      type: 'reject',
      schedule_id: this.scheduled_id,
    };

    this.__websocket.socket.emit('videocall', message);
  }

  public initPeer(peerId): string {
    let jsondata = localStorage.getItem('portal_login_token');
    ////console.log(jsondata);

    if (!this.peer || this.peer.disconnected) {
      const peerJsOptions: Peer.PeerJSOption = {
        debug: 3,
        config: {
          iceServers: [
            {
              urls: [
                'stun:stun1.l.google.com:19302',
                'stun:stun2.l.google.com:19302',
              ],
            },
          ],
        },
      };
      const iceConfiguration = {
        iceServers: [
          {
            urls: [
              'stun:stun1.l.google.com:19302',
              'stun:stun2.l.google.com:19302',
            ],

            credentials: jsondata,
          },
        ],
      };
      try {
        this.peer = new Peer(peerId, peerJsOptions);
        this.peerConnection = new RTCPeerConnection(iceConfiguration);
        //////console.log('this.peer', this.peer);
        return peerId;
      } catch (error) {
        //////console.log(error);
      }
    }
  }

  public async establishMediaCall(remotePeerId: string, callFrom) {
    ////console.log('Call To Remote', remotePeerId);
    this.remote_user_id = remotePeerId;
    this.remoteCallFrom = callFrom;
    const today = new Date();
    let startTime =
      today.getHours() +
      ':' +
      String(today.getMinutes()).padStart(2, '0') +
      ':' +
      today.getSeconds();
    this.call_start_time = startTime;
    ////console.log(this.call_start_time,'Services');

    //////console.log('callFrom', this.remoteCallFrom);
    try {
      // this.__websocket.socket.on('videocall', (data) => {
      //   ////console.log(data, 'videocall');
      // })
      // const devices = await navigator.mediaDevices.enumerateDevices()
      // const deviceKinds = devices.map(d => d.kind)
      // let params = { audio: true, video: false }
      // if (deviceKinds.includes('videoinput')) {
      //   params = { audio: true, video: true }
      // }
      // const stream = await navigator.mediaDevices.getUserMedia(params);
      // // const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      // ////console.log(callFrom, 'connection', remotePeerId);
      // const connection: any = this.peer.connect(remotePeerId, { metadata: { callFrom } });
      // ////console.log(connection);
      // this.completeConnection = connection.label;
      // //////console.log('stream',stream);
      // //////console.log('this.completeConnection', this.completeConnection);
      // connection.on('error', err => {
      //   ////console.log(err);
      // });
      // //////console.log('line 82');
      // this.mediaCall = this.peer.call(remotePeerId, stream);
      // // this.ringingToneStart()
      // // this.ringingAudio.play()
      // //////console.log('<line 87>', this.mediaCall);
      // this.call_start_time = startTime;
      // if (!this.mediaCall) {
      //   const errorMessage = 'Unable to connect to remote peer';
      //   throw new Error(errorMessage);
      // }
      // // ////console.log('OnCalloffer');
      // let jsondata = JSON.parse(localStorage.getItem('portal_login_data'));
      // this.mediaCall.on('stream',
      //   (remoteStream) => {
      //     ////console.log(remoteStream);
      //     this.remoteStreamBs.next(remoteStream);
      //     // this.ringingAudio.pause()
      //     // this.ringingToneEnd()
      //   });
      // this.mediaCall.on('error', err => {
      //   ////console.log('ERROR----------', err);
      //   // console.error(err);
      //   this.isCallStartedBs.next(false);
      // });
      // this.localStreamBs.next(stream);
      // this.isCallStartedBs.next(true);
      // this.mediaCall.on('close', () => {
      //   this.onCallClose();
      // });
      // this.peer.on('disconnected', () => {
      //   ////console.log('disconnected 117');
      //   this.receiverDiv = false;
      //   ////console.log('disconnected establish');
      //   this.ringingToneEnd();
      //   this.peer?.disconnect();
      //   this.peer?.destroy();
      // })
    } catch (ex) {
      console.error(ex);
      //////console.log(ex);
      this.isCallStartedBs.next(false);
    }
  }

  public async enableCallAnswer() {
    try {
      // ////console.log('A1');
      const devices = await navigator.mediaDevices.enumerateDevices();
      const deviceKinds = devices.map((d) => d.kind);
      let params = { audio: true, video: true };
      let videocallData: any = {
        audio: true,
        video: {
          width: '10px',
          height: '20px',
          facingMode: 'camNode',
        },
      };
      //////console.log('A2');
      if (deviceKinds.includes('videoinput')) {
        params = { audio: true, video: true };
      }
      //////console.log('A3');

      // ////console.log('A4');
      this.peer.on('call', async (call) => {
        ////console.log("RECEIVED CALL======", call);
        //////console.log('A5');
        this.ringingToneStart();

        // const answer = await this.peerConnection.createAnswer();
        // ////console.log(answer);

        // await this.peerConnection.setLocalDescription(answer);
        let jsondata = JSON.parse(localStorage.getItem('portal_login_data'));

        let callfrom = '';
        if (
          call.metadata &&
          call.metadata.callFrom &&
          typeof call.metadata.callFrom != undefined &&
          call.metadata.callFrom != null &&
          call.metadata.callFrom != ''
        ) {
          callfrom = call.metadata.callFrom;
        }
        const conf = confirm(`Incoming call from ${callfrom}`);
        //////console.log('conf', conf);
        // ////console.log('A6');

        const stream = await navigator.mediaDevices.getUserMedia(params);
        if (conf) {
          // ////console.log(1)
          this.elebelcallflag = true;
          const stream = await navigator.mediaDevices.getUserMedia(params);
          ////console.log(stream, 'stream-local');

          this.localStreamBs.next(stream);
          const today = new Date();
          let startTime =
            today.getHours() +
            ':' +
            String(today.getMinutes()).padStart(2, '0') +
            ':' +
            today.getSeconds();
          this.call_start_time = startTime;

          this.ringingToneEnd();
          this.receiverDiv = true;
          let jsondata = JSON.parse(localStorage.getItem('portal_login_data'));

          // let message_offer = { sender_id: jsondata._id, receiver_id: 'remotePeerId', type: 'answer', answer: answer }
          // ////console.log(message_offer);

          // this.__websocket.socket.emit('videocall', message_offer);
          this.__websocket.socket.emit('callReceived');
        } else {
          // ////console.log(2)
          this.ringingToneEnd();
          this.receiverDiv = false;
          this.peer?.disconnect();
          this.peer?.destroy();
          let message = {
            sender_id: jsondata._id,
            receiver_id: 'connectedUser',
            type: 'not-answered',
          };
          this.__websocket.socket.emit('callNotReceived', message);
        }
        //////console.log('A7');
        this.mediaCall = call;
        this.isCallStartedBs.next(true);

        this.mediaCall.answer(stream);
        //////console.log('A8');
        this.mediaCall.on('stream', (remoteStream) => {
          this.remoteStreamBs.next(remoteStream);
        });
        //////console.log('A9');
        this.mediaCall.on('error', (err) => {
          //////console.log('ERROR----------', err)
          this.ringingToneEnd();
          this.isCallStartedBs.next(false);
          //////console.log(err);
        });
        //////console.log('A10');
        this.mediaCall.on('close', () => {
          this.onCallClose();
          this.ringingToneEnd();
        });
        //////console.log('A11');
      });

      this.peer.on('disconnected', () => {
        //////console.log('disconnected 182')
        this.receiverDiv = false;
        this.ringingToneEnd();
        // this.peer?.disconnect();
        this.peer?.destroy();
        //////console.log('disconnected enable fired')
      });
    } catch (ex) {
      ////console.log(ex);
      this.isCallStartedBs.next(false);
    }
  }
  private onCallClose() {
    // alert('close');
    this.receiverDiv = false;
    const today = new Date();
    this.call_end_time =
      today.getHours() +
      ':' +
      String(today.getMinutes()).padStart(2, '0') +
      ':' +
      today.getSeconds();
    // ////console.log('call_end_time',this.remoteStreamBs.value);
    // alert('remoteStreamBs' + this.remoteStreamBs);
    this.remoteStreamBs?.value?.getTracks().forEach((track) => {
      track.stop();
      //  alert('close1');
      // this.peer.destroy();
    });
    this.localStreamBs?.value?.getTracks().forEach((track) => {
      track.stop();
      //  alert('close2');
    });
    //////console.log('before peer disconnect');
    this.peer.disconnect(); // cannot connect again after disconnect ..so closing
    this.ringingToneEnd(); // newly added on 14-4-2022
  }

  public closeMediaCall() {
    // alert('close media call');
    this.receiverDiv = false;
    //this.mediaCall.close();

    if (this.mediaCall) {
      //  alert('if media')
      this.onCallClose();
    } else {
      //  alert(' else media call close loop ');
    }
    this.isCallStartedBs.next(false);
  }

  public destroyPeer() {
    this.mediaCall?.close();
    this.peer?.disconnect();
    this.peer?.destroy();
  }

  public muteaudio() {
    //////console.log('before audio', this.localStreamBs.value.getAudioTracks()[0].enabled);

    this.localStreamBs.value.getAudioTracks()[0].enabled =
      !this.localStreamBs.value.getAudioTracks()[0].enabled;
  }

  public mutevideo() {
    //////console.log('+++++226 call service');
    //////console.log('before video', this.localStreamBs.value.getVideoTracks()[0].enabled);

    this.localStreamBs.value.getVideoTracks()[0].enabled =
      !this.localStreamBs.value.getVideoTracks()[0].enabled;
  }

  public ringingToneStart() {
    // this.ringingAudio.load();

    try {
      //console.log('this.audio1', this.audio1);
      this.audio1.volume = 1;
      this.audio1.play();
      //console.log(' this.audio1.paused', this.audio1.paused);
      // this.createAndPlayAudio();
      // this.ringingAudio.loop = true;
      // //console.log('ringtone', this.ringingAudio);
      // this.ringingAudio.play();
      this.isIncomingCall = true;
    } catch (error) {
      console.error(error);
    }
  }
  public ringingToneEnd() {
    // this.ringingAudio.pause();
    //console.log('this.audio1', this.audio1);
    // this.audio.pause();
    this.audio1.pause();
  }

  public yourConn = new RTCPeerConnection({
    iceServers: [
      {
        urls: 'stun:stun.l.google.com:19302',
      },
      {
        urls: 'stun:stun1.l.google.com:19302',
      },
      {
        urls: 'stun:stun2.l.google.com:19302',
      },
    ],
  });
  // async getMessages() {

  //   try {
  //     const devices = await navigator.mediaDevices.enumerateDevices()
  //     const deviceKinds = devices.map(d => d.kind)
  //     let params = { audio: true, video: false }
  //     if (deviceKinds.includes('videoinput')) {
  //       params = { audio: true, video: true }
  //     }
  //     const stream = await navigator.mediaDevices.getUserMedia(params);
  //     let callfrom = '';

  //     this.ringingToneStart()
  //     this.ringingAudio.play()

  //     const remoteStream = new MediaStream();

  //     this.yourConn.ontrack = (event) => {
  //       event.streams[0].getTracks().forEach((track) => {
  //         // remoteStream.addTrack(track);
  //         ////console.log(track);

  //       });
  //     };

  //     stream.getTracks().forEach((track) => {
  //       this.yourConn.addTrack(track, stream);
  //     });
  //     this.remoteStreamBs.next(remoteStream);

  //   } catch (err) {

  //   }
  // }

  sendMessage(payload): void {
    this.socket.emit('send-message', payload);
  }
}
