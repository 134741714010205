import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiUrl: string;
  local_data: any;
  local_token: any;
  stripe_url: any;
  STRIPE_PUBLISHABLE_KEY: any;
  STRIPE_Secret_key: any;
  create_user_id: string;
  constructor(private __http: HttpClient) {
    this.apiUrl = environment.api_url;
    this.stripe_url = environment.stripeUrl;
    this.STRIPE_PUBLISHABLE_KEY = environment.stripeToken;
    this.STRIPE_Secret_key = environment.Secret_key;
    this.local_data = JSON.parse(localStorage.getItem('portal_login_data'));
    this.local_token = localStorage.getItem('portal_login_token');
  }

  register(registerData): Observable<any> {
    return this.__http.post(`${this.apiUrl}/user/register`, registerData);
  }
  login(loginCredentials): Observable<any> {
    return this.__http.post(`${this.apiUrl}/user/login`, loginCredentials);
  }

  userDetails(data: any): Observable<any> {
    console.log('UserDetails');
    return this.__http.get(`${this.apiUrl}/user/${data}`);
  }

  listUser(is_blocked, page = '0', limit = '10'): Observable<any> {
    return this.__http.get(`${this.apiUrl}/user`, {
      params: {
        is_blocked,
        page,
        limit,
      },
    });
  }
  listUserForLoggedInUser(
    is_blocked,
    page = '0',
    limit = '10',
    search_key = '',
    country = ''
  ): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(`${this.apiUrl}/user/user-list-logged-in-user`, {
      params: {
        is_blocked,
        page,
        limit,
        search_key,
        country,
      },
      headers: { Authorization: this.local_token },
    });
  }

  detailsUser(id: any): Observable<any> {
    this.create_user_id = localStorage.getItem('chatCelebrity');
    if (this.create_user_id) {
      return this.__http.get(`${this.apiUrl}/user/${id}`);
    } else {
      return this.__http.get(`${this.apiUrl}/user/${id}`, {
        headers: { Authorization: this.local_token },
      });
    }
  }

  forgotPassword(data): Observable<any> {
    return this.__http.post(`${this.apiUrl}/user/forgot-password`, data);
  }
  verifyOtp(data): Observable<any> {
    return this.__http.post(`${this.apiUrl}/user/verify-otp`, data);
  }
  setNewPassword(data): Observable<any> {
    return this.__http.post(`${this.apiUrl}/user/set-new-password`, data);
  }
  setUserLanguage(id, lang_id): Observable<any> {
    return this.__http.put(
      `${this.apiUrl}/user/setLanguage/${id}`,
      { lang_id },
      {
        headers: { Authorization: this.local_token },
      }
    );
  }
  myProfile(): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(`${this.apiUrl}/user/my-profile`, {
      headers: { Authorization: this.local_token },
    });
  }
  updateMyProfile(profileData): Observable<any> {
    return this.__http.put(`${this.apiUrl}/user/my-profile`, profileData, {
      headers: { Authorization: this.local_token },
    });
  }
  updateProfileImage(imageData): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.put(
      `${this.apiUrl}/user/change-profile-image`,
      imageData,
      {
        headers: { Authorization: this.local_token },
      }
    );
  }
  changeMyPassword(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/change-my-password`, data, {
      headers: { Authorization: this.local_token },
    });
  }

  reportProfile(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/report-profile`, data, {
      headers: { Authorization: this.local_token },
    });
  }

  checkProfileReported(profile_user_id): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(
      `${this.apiUrl}/report-profile/check/${profile_user_id}`,
      {
        headers: { Authorization: this.local_token },
      }
    );
  }

  removeProfileReport(profile_user_id): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.delete(
      `${this.apiUrl}/report-profile/${profile_user_id}`,
      {
        headers: { Authorization: this.local_token },
      }
    );
  }

  savePaymentInfo(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(
      `${this.apiUrl}/payment-info`,
      { paymentInfo: data },
      {
        headers: { Authorization: this.local_token },
      }
    );
  }
  getPaymentInfo(type = 'CARD'): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(`${this.apiUrl}/payment-info`, {
      headers: { Authorization: this.local_token },
      params: {
        type,
      },
    });
  }
  gallery(id): Observable<any> {
    // this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/getUserGallery`, id, {
      // headers: { Authorization: this.local_token }
    });
  }
  rateingInfo(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/giverating`, data, {
      headers: { Authorization: this.local_token },
    });
  }
  checkSchedule(profile_user_id): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(
      `${this.apiUrl}/chat/schedulerondate/${profile_user_id}`,
      {
        headers: { Authorization: this.local_token },
      }
    );
  }

  getCountryList(): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(`${this.apiUrl}/user/getcountrylist`, {
      headers: { Authorization: this.local_token },
    });
  }

  getavailablelists(): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(`${this.apiUrl}/user/timeslot`, {
      headers: { Authorization: this.local_token },
    });
  }

  deleteslot(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/delete-slot`, data, {
      headers: { Authorization: this.local_token },
    });
  }

  giveingtips(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/givetips`, data, {
      headers: { Authorization: this.local_token },
    });
  }

  viewGallary(): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/gallerylist`, '', {
      headers: { Authorization: this.local_token },
    });
  }

  uploadImgGallary(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/upload-gallery-image`, data, {
      headers: { Authorization: this.local_token },
    });
  }

  deleteImg(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.delete(`${this.apiUrl}/user/image/${data}`, {
      headers: { Authorization: this.local_token },
    });
  }

  requestpayment(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/withdraw`, data, {
      headers: { Authorization: this.local_token },
    });
  }

  setAsProfilePicture(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/setasprofileimg`, data, {
      headers: { Authorization: this.local_token },
    });
  }
  topUpRequest(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/topuprequest`, data, {
      headers: { Authorization: this.local_token },
    });
  }

  stripeToken(data): Observable<any> {
    return this.__http.post(`${this.stripe_url}/v1/tokens`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${this.STRIPE_PUBLISHABLE_KEY}`,
      },
    });
  }

  stripePayments(data): Observable<any> {
    return this.__http.post(`${this.stripe_url}/v1/charges`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${this.STRIPE_Secret_key}`,
      },
    });
  }

  setChatCallPrice(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.put(`${this.apiUrl}/user/update-pricing`, data, {
      headers: { Authorization: this.local_token },
    });
  }

  // addslots(data): Observable<any> {
  //   this.local_token = localStorage.getItem('portal_login_token');
  //   return this.__http.post(`${this.apiUrl}/user/create-timeslot`, data, {
  //     headers: { Authorization: this.local_token }
  //   });
  // }
  addslots(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(
      'https://hi2u.app:3020/api/user/create-timeslot',
      data,
      {
        headers: { Authorization: this.local_token },
      }
    );
  }
  request_blueTick(data): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/user/blue-tick-verified`, data, {
      headers: { Authorization: this.local_token },
    });
  }
}
