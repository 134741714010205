export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAUkfuwYl9n3156IM0TVkMiQeqeQWdncG8',
    authDomain: 'hi2u-edc47.firebaseapp.com',
    projectId: 'hi2u-edc47',
    storageBucket: 'hi2u-edc47.appspot.com',
    messagingSenderId: '305550714923',
    appId: '1:305550714923:web:17a54b8d83cd612012e26e',
    measurementId: 'G-60LLZKN33P',
  },
  // url: 'http://localhost:4200',
  url: 'https://hi2u.app:/',
  playStoreLink:
    'https://play.google.com/store/apps/details?id=com.hi2u.brainium',
  appStoreLink: 'https://apps.apple.com/us/app/app-name/id1234567890',

  api_url: 'https://hi2u.app:3020/api',
  image_url: 'https://hi2u.app:3020/',
  socket_endpoint: 'https://hi2u.app:3020/',
  stripeUrl: 'https://api.stripe.com',
  paypal_Client_Id:
    'AW_1tQPGMiGl5-KcVc63vO6xdCp-AQnPmfwf3HrwNDL6BsIBHeYOg7nxGo4ZDnGdLUXvZT4P-T3Qrqql',
  // stripeToken:'pk_live_UgGWUtHdPOGhrsZouBaigKeY00oaFhaDr9',
  // Secret_key:'sk_live_gH72vOM4FyWOfOhFUacoNUpC00NkRQLLyD',
  stripeToken:
    'pk_test_51PNyrjHG8pqSX4WhurbnCrDayCutN51YLQYlV8NbIMlqKZz45HdSaBs8FLtTllVkXVV34meRlJ42TbzygufkqXl900gig3eFvS',
  Secret_key:
    'sk_test_51PNyrjHG8pqSX4WhKkKAOqSOCKADrtd8LHqArmG7mJSLCWvz7Kd0295TYvDw1fJLvpkg7LojWM8As8ZzhB7Yq2L300Y6J6yHyo',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
