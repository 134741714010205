import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { io } from 'socket.io-client';
import { UserService } from '../user/user.service';
import { ToastrService } from 'ngx-toastr';
import { CallService } from '../call/call.service';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  apiUrl: string;
  local_data: any;
  local_token: any;
  socket: any;
  callRecived: any = false;
  constructor(private __http: HttpClient, private __user: UserService) {
    this.apiUrl = environment.api_url;
    this.local_data = JSON.parse(localStorage.getItem('portal_login_data'));
    this.local_token = localStorage.getItem('portal_login_token');
    //////console.log('WEBSERVICE CALLED +++++++++++++++')
    this.setupSocket();

    // this.socket.on('receiveMessage', (data) => {
    //   //////console.log('receivedmsginangular----------',data);
    //   this.getChatDetails()
    // });
    // this.socket.on('online', (data) => {
    //   this.onlineUsers = Object.keys(data.onlineUsers)
    //   // this.onlineUsers = Object.values(data.onlineUsers)
    //   //////console.log('onlineUsers----------',data.onlineUsers);
    // });
  }
  setupSocket() {
    this.local_token = localStorage.getItem('portal_login_token');
    this.socket = io(environment.socket_endpoint, {
      auth: {
        token: this.local_token,
      },
    });
    this.socket.on('connect', () => {
      console.log('Connected');
    });
    this.socket.on('disconnect', (reason) => {
      console.log('Disconnected', reason);
    });
    this.socket.on('reconnect', () => {
      console.log('Reconnect');
    });
  }
}
